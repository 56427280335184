import { createVNode, render } from 'vue'
// import { mount } from 'mount-vue-component'

import Modal from './Modal.vue'

let VueApp

class InstanceRegistry {
  entries

  constructor() {
    this.entries = []
  }

  add(entry) {
    this.entries.push(entry)
  }

  remove(entry) {
    const index = this.entries.indexOf(entry)
    this.entries.splice(index, 1)
  }

  walk(callback) {
    // Walk a copy of the array so that the callback is allowed to remove the instance
    this.entries = [...this.entries].filter((e) => {
      const ret = callback(e)
      return !(ret === true)
    })
  }
}

const instances = new InstanceRegistry()

const ModalProgrammatic = {
  // ref: https://stackoverflow.com/questions/65163775/how-to-programmatically-destroy-unmount-vue-js-3-components

  open(params) {
    console.log('open')
    const componentParams =
      typeof params === 'string'
        ? {
            content: params,
          }
        : { ...params }

    let slot
    if (Array.isArray(componentParams.content)) {
      slot = componentParams.content
      delete componentParams.content
    }

    const defaultParams = {
      programmatic: { instances },
      active: true, // set the active state to true
      destroyOnHide: true, // set destroy on hide true
    }

    const propsData = Object.assign(defaultParams, componentParams)
    propsData.promise = new Promise((p1, p2) => {
      propsData.programmatic.resolve = p1
      propsData.programmatic.reject = p2
    })

    const defaultSlot = () => slot

    const app = VueApp
    let removeFromRoot = false
    let parent = params?.parent || document.querySelector('body')
    let vnode = createVNode(Modal, propsData, defaultSlot)
    // console.log('app', app)

    if (app?._context) {
      vnode.appContext = app._context
      // a hack to get component to show up in vue-devtools
      removeFromRoot = true
      // console.log(vnode)
      app?._instance?.subTree?.children?.push(vnode)
      // console.log('children', app?._instance?.subTree?.children?.length)
    }

    vnode.destroy = () => {
      if (removeFromRoot) {
        let index = app?._instance?.subTree?.children?.findIndex(
          (v) => v.component?.uid === vnode.component?.uid
        )
        if (index > -1) {
          app?._instance?.subTree?.children?.splice(index, 1)
        }
      }
      if (parent) render(null, parent)
      parent = null
      vnode = null
    }

    if (parent) {
      render(vnode, parent)
    } else if (typeof document !== 'undefined') {
      parent = document.createElement('div')
      render(vnode, parent)
    }

    // console.log('vnode', vnode)
    return vnode.component
  },
}

const Plugin = {
  install(app, options) {
    console.log('LModal Options', options)
    VueApp = app

    // registerComponent(Vue, Modal)
    app.component('LiffModal', Modal)

    // registerComponentProgrammatic(app, 'modal', ModalProgrammatic)

    // https://stackoverflow.com/questions/42613061/vue-js-making-helper-functions-globally-available-to-single-file-components
    app.mixin({
      methods: {
        openModal: ModalProgrammatic.open,
      },
    })
  },
}

export default Plugin

export { ModalProgrammatic, Modal as BModal }
